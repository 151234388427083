import colors from '../constants/colors';
import moment from 'moment';
export const getStatusColors = (status) => {
  const color = colors[status.toLowerCase()];
  const lightColor = colors[`${status.toLowerCase()}Light`];
  return [color, lightColor];
};

export function isSameDay(a, b) {
  if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
  // Compare least significant, most likely to change units first
  // Moment's isSame clones moment inputs and is a tad slow
  return (
    a.date() === b.date() && a.month() === b.month() && a.year() === b.year()
  );
}

export function createSlug(str) {
  return str
    .toLowerCase()
    .trim()
    .replace(/[áàäâãåā]/g, 'a')
    .replace(/[éèëêēė]/g, 'e')
    .replace(/[íìïîī]/g, 'i')
    .replace(/[óòöôõō]/g, 'o')
    .replace(/[úùüûū]/g, 'u')
    .replace(/[ñń]/g, 'n')
    .replace(/[çć]/g, 'c')
    .replace(/[ß]/g, 'ss')
    .replace(/[^\w\s-]/g, '') // Elimina caracteres especiales excepto guiones y espacios
    .replace(/[\s_-]+/g, '-') // Reemplaza espacios, guiones bajos y múltiples guiones por un solo guión
    .replace(/^-+|-+$/g, ''); // Elimina guiones al principio y al final
}