import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet, Pressable } from 'react-native';
import * as DocumentPicker from 'expo-document-picker';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import Parse from 'parse';
import { createSlug } from '../utils/utils';
import { uploadDocument } from '../services/api/restaurant';
import { AuthContext } from '../contexts/AuthContext';


const FileUploader = ({ title, documentType, onFileSelect }) => {
  const { restaurant } = useContext(AuthContext);
  const [file, setFile] = useState(null);

  // console.log('restaurant', restaurant);

  const pickDocument = async () => {
    try {
      const result = await DocumentPicker.getDocumentAsync({
        type: '*/*',
        copyToCacheDirectory: false,
      });

      if (result.assets && result.assets.length > 0) {
        const selectedFile = result.assets[0];
        // console.log('selectedFile', selectedFile.name, selectedFile.uri);
        const parseFile = new Parse.File(createSlug(selectedFile.name), { base64: selectedFile.uri });
        
        await parseFile.save();
        console.log('parseFile', parseFile);
        const uploadResponse = await uploadDocument(documentType, parseFile._url, restaurant.id);

        // console.log('uploadResponse', uploadResponse);
        
        setFile(selectedFile);
        // onFileSelect(parseFile);
      }
    } catch (err) {
      console.error('Error al seleccionar o guardar el archivo:', err);
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <Pressable onPress={pickDocument} style={styles.uploadArea}>
        {file ? (
          <View style={styles.fileInfo}>
            <Icon name="description" size={24} color={colors.primary} />
            <Text style={styles.fileName} numberOfLines={1} ellipsizeMode="middle">
              {file.name}
            </Text>
          </View>
        ) : (
          <View style={styles.uploadIcon}>
            <Icon name="add" size={40} color={colors.primary} />
            <Text style={styles.uploadText}>Subir archivo</Text>
          </View>
        )}
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    width: 300,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  uploadArea: {
    borderWidth: 2,
    borderColor: colors.lightGrey,
    borderStyle: 'dashed',
    borderRadius: 8,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadIcon: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '90%',
  },
  fileName: {
    marginLeft: 10,
    fontSize: 14,
    color: colors.darkGrey,
    flex: 1,
  },
  uploadText: {
    marginTop: 5,
    fontSize: 14,
    color: colors.primary,
  },
});

export default FileUploader;
