import React, { useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList, Dimensions } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import { AuthContext } from '../contexts/AuthContext';
import colors from '../constants/colors';
import { Ionicons } from '@expo/vector-icons';
import CustomDateRangePicker from './dashboard/CustomDatePicker/CustomDateRangePicker';
import News from './dashboard/News';
import { getOrders } from '../services/api/orders'; // Asegúrate de importar getSalesData correctamente
// import moment from 'moment';
import SalesOverview from './dashboard/SalesOverview';
import TotalMetrics from './dashboard/TotalMetrics';
import PerformanceMetrics from './dashboard/PerformanceMetrics';
import AveragePreparationTime from './dashboard/AveragePreparationTime';
import OrdersChart from './dashboard/OrdersChart';
// import { getRestaurantsForUser } from '../services/api/restaurant';
import { DateContext } from '../contexts/DateContext';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export default function Dashboard() {
  const { restaurant, user, restaurants, selectedRestaurant, setSelectedRestaurant } = useContext(AuthContext);
  const { startDate, endDate, updateDates } = useContext(DateContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleDatesChange = ({ startDate, endDate }) => {
    updateDates(startDate, endDate);
  };

  const renderItem = ({ item }) => (
    <View>
      {item}
    </View>
  );

  const dashboardItems = [
    <View style={[styles.row, styles.salesnews, { padding: 10 }]} key="salesnews">
      <SalesOverview key="sales" startDate={startDate} endDate={endDate} />
      <News key="news" />
    </View>,
    <View style={{ padding: 10 }} key="containerMetrics">
      <TotalMetrics key="metrics" startDate={startDate} endDate={endDate} />
    </View>,
    <View style={[styles.row, { padding: 10 }, styles.containerPerformance]} key="performance">
      <View style={[styles.column, { width: isMobile ? '100%' : '73%' }]}>
        <PerformanceMetrics startDate={startDate} endDate={endDate} />
      </View>
      <View style={[styles.column, { width: isMobile ? '100%' : '25%' }]}>
        <AveragePreparationTime startDate={startDate} endDate={endDate} />
        <OrdersChart startDate={startDate} endDate={endDate} title="# of Orders" type="ordersNumber" />
        <OrdersChart startDate={startDate} endDate={endDate} title="% of Cancelled Orders" type="ordersCanceled" />
      </View>
    </View>
  ];

  const handleExportCSV = async () => {
    try {
      // Mostrar un indicador de carga (puedes usar un estado para mostrar un spinner)
      setIsLoading(true);

      // Obtener los datos de ventas
      const salesData = await getOrders(restaurant.id, startDate, endDate, [
        'objectId',
        'orderNumber',
        'total',
        'subtotals',
        'taxData',
        'revShare',
        'receivedAt',
        'status',
        'dishes',
        'refundAmount',
        'refundReason',
        'createdAt',
        'user'
      ], 1000, ['DELIVERED']);

      // Convertir los datos a formato CSV
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += "ID,Order No,Total,Taxes,Revenue Share,Received At,Status,Dishes,Refund Amount,Refund Reason,Created At,Client Name\n";
      salesData.forEach(item => {
        let dishes = item.dishes.map(dish => ({
          name: dish.name,
          quantity: dish.count,
          price: dish.price
        }));

        let dishString = JSON.stringify(dishes);

        dishString = dishString.replace(/,/g, ';');

        csvContent += `${item.objectId},${item.orderNumber},${item.subtotalData.totalRestaurantSubtotal},${item.taxData.foodTaxes.total},${item.revShare},${item.receivedAt ? item.receivedAt.iso : ''},${item.status},"${dishString}",${item.refundAmount ? (item.refundAmount / 100) : 0},${item.refundReason ? item.refundReason : ''},${item.createdAt},${item?.user?.full_name || ''} \n`;
      });

      // console.log(csvContent);

      // return;

      // Codificar el contenido CSV para URL
      const encodedUri = encodeURI(csvContent);

      // Crear un elemento de enlace temporal
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `ventas_${startDate.toISOString().split('T')[0]}_${endDate.toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link); // Necesario para Firefox

      // Descargar el archivo
      link.click();

      // Limpiar
      document.body.removeChild(link);
      setIsLoading(false);

      // Opcional: Mostrar un mensaje de éxito
      // alert("El archivo CSV ha sido descargado exitosamente.");
    } catch (error) {
      console.error('Error al exportar CSV:', error);
      alert("Error al exportar los datos. Por favor, intente de nuevo.");
      setIsLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>Merchant Dashboard</Text>
        <View style={styles.headerRight}>
          {restaurants.length > 1 && (
            <View style={styles.restaurantSelector}>
              <Picker
                selectedValue={selectedRestaurant}
                onValueChange={(itemValue) => setSelectedRestaurant(itemValue)}
                style={styles.picker}
              >
                {restaurants.map((restaurant) => (
                  <Picker.Item key={restaurant.id} label={restaurant.get('restaurant_name')} value={restaurant.id} />
                ))}
              </Picker>
            </View>
          )}
          <View style={styles.dateRangeContainer}>
            <CustomDateRangePicker
              startDate={startDate}
              endDate={endDate}
              onDatesChange={handleDatesChange}
            />
          </View>
          <TouchableOpacity style={styles.exportButton} onPress={handleExportCSV}>
            {isMobile ? (
              <Ionicons name={isLoading ? "document-text" : "document-text-outline"} size={24} color="white" />
            ) : (
              <Text style={styles.exportButtonText}>{isLoading ? 'Exportando...' : 'Export CSV'}</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
      <FlatList
        style={{ zIndex: 0 }}
        data={dashboardItems}
        renderItem={renderItem}
        keyExtractor={(item, index) => index.toString()}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.lightBackground,
    padding: 10,
  },
  header: {
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: isMobile ? 'center' : 'space-between',
    alignItems: 'center',
    marginBottom: isMobile ? 20 : 40,
    zIndex: 1,
    paddingHorizontal: isMobile ? 10 : 30,
  },
  headerText: {
    fontSize: isMobile ? 20 : 24,
    fontWeight: 'bold',
    color: colors.darkGrey,
  },
  headerRight: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginTop: isMobile ? 15 : 0,
  },
  dateRangeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
    backgroundColor: '#f5f5f5',
    padding: 5,
    borderRadius: 5,
  },
  exportButton: {
    backgroundColor: colors.primary,
    paddingHorizontal: isMobile ? 6 : 15,
    paddingVertical: isMobile ? 6 : 10,
    borderRadius: 5,
  },
  exportButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: {
    // marginHorizontal: 10,
  },
  salesnews: {
    flexDirection: isMobile ? 'column' : 'row',
  },
  containerPerformance: {
    flexDirection: isMobile ? 'column-reverse' : 'row',
  },
  restaurantSelector: {
    marginRight: 10,
    width: isMobile ? '100%' : 300,
    marginBottom: isMobile ? 10 : 0,
  },
  picker: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 10,
  },
});