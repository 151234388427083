import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import DateTimePicker from '@react-native-community/datetimepicker';
import colors from '../constants/colors';

// const daysOptions = ['Everyday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Weekends', 'Weekdays'];
const daysOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function OpeningHours({ schedule, handleScheduleChange }) {
  const [selectedDay, setSelectedDay] = useState('Monday');
  const [showFromPicker, setShowFromPicker] = useState(false);
  const [showToPicker, setShowToPicker] = useState(false);

  // console.log(schedule);

  const handleDayPress = (day) => {
    setSelectedDay(day);
  };

  const handleTimeChange = (timeType, event, selectedTime) => {
    if (event.type === 'set') {
      const newSchedule = { ...schedule };
      newSchedule[selectedDay] = { ...newSchedule[selectedDay], [timeType]: selectedTime };
      handleScheduleChange(newSchedule);
    }
    setShowFromPicker(false);
    setShowToPicker(false);
  };

  return (
    <View style={styles.container}>
      <View style={{ width: 400 }}>
        <View>
          <Text style={styles.sectionTitle}>Opening Hours</Text>
        </View>
      </View>
      <View style={{ width: 500 }}>
        <View style={styles.daysContainer}>
          {daysOptions.map((day, index) => (
            <TouchableOpacity
              key={index}
              style={[styles.dayButton, selectedDay === day && styles.selectedDay]}
              onPress={() => handleDayPress(day)}
            >
              <Text style={[styles.dayText, selectedDay === day && styles.selectedDayText]}>{day}</Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={styles.timeContainer}>
          <Text style={[styles.dayText, {fontWeight: 'bold'}]}>{selectedDay}</Text>
          <Text style={{fontSize: 16, fontWeight: 'bold'}}>From</Text>
          <TouchableOpacity onPress={() => setShowFromPicker(true)} style={styles.timeButton}>
            <Text>{schedule[selectedDay]?.from?.toLocaleTimeString() || '7:00 AM'}</Text>
          </TouchableOpacity>
          <Text style={{fontSize: 16, fontWeight: 'bold'}}>To</Text>
          <TouchableOpacity onPress={() => setShowToPicker(true)} style={styles.timeButton}>
            <Text>{schedule[selectedDay]?.to?.toLocaleTimeString() || '10:30 PM'}</Text>
          </TouchableOpacity>
        </View>
        {showFromPicker && (
          <DateTimePicker
            value={schedule[selectedDay]?.from || new Date()}
            mode="time"
            is24Hour={false}
            display="default"
            onChange={(event, selectedTime) => handleTimeChange('from', event, selectedTime)}
          />
        )}
        {showToPicker && (
          <DateTimePicker
            value={schedule[selectedDay]?.to || new Date()}
            mode="time"
            is24Hour={false}
            display="default"
            onChange={(event, selectedTime) => handleTimeChange('to', event, selectedTime)}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 20,
    flexDirection: 'row',
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: colors.darkGrey,
  },
  daysContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  dayButton: {
    padding: 10,
    marginRight: 10,
    marginBottom: 10,
    borderRadius: 4,
    backgroundColor: '#f0f0f0',
  },
  selectedDay: {
    backgroundColor: colors.primary,
  },
  dayText: {
    fontSize: 16,
  },
  selectedDayText: {
    color: 'white',
  },
  timeContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  timeButton: {
    padding: 10,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 8,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
    // marginBottom: 10,
    flex: 1,
  },
});