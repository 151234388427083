import Parse from 'parse';

export const getRestaurant = async (restaurantId) => {
  const Restaurant = Parse.Object.extend('Restaurant');
  const query = new Parse.Query(Restaurant);

  query.equalTo('objectId', restaurantId);

  const restaurant = await query.first();

  return restaurant;
};

export const getRestaurantMenuAndCategories = async (restaurantId) => {
  try {
    const response = await Parse.Cloud.run('getDishesForRestaurantMenuV2', {
      page: 0,
      limit: 1000,
      filter: {
        restaurantId: restaurantId
      }
    });

    if (!response || !response.items) {
      console.error('Unexpected response structure');
      throw new Error('Unexpected response structure from server');
    }

    const { dishes = [], dishVariants = [] } = response.items;

    // console.log(`Dishes: ${dishes.length}, DishVariants: ${dishVariants.length}`);

    const categoriesSet = new Set();
    const dishesByCategory = {};
    const dishesMap = new Map();

    dishes.forEach(dish => {
      const category = dish.get('category') || 'Sin categoría';

      categoriesSet.add(category);

      if (!dishesByCategory[category]) {
        dishesByCategory[category] = [];
      }

      const dishData = {
        ...dish.toJSON(),
        variants: []
      };

      dishesByCategory[category].push(dishData);
      dishesMap.set(dish.id, dishData);
    });

    dishVariants.forEach(variant => {
      const variantData = variant.toJSON();
      const dishId = variant.get('dish').id;
      const dish = dishesMap.get(dishId);

      if (dish) {
        dish.variants.push(variantData);
      }
    });

    // console.log(`Categories: ${categoriesSet.size}`);

    return {
      categories: Array.from(categoriesSet),
      dishes_by_category: dishesByCategory,
      all_dishes: Array.from(dishesMap.values())
    };
  } catch (error) {
    console.error('Error fetching restaurant menu and categories:', error);
    throw error;
  }
};


export const addNewCategory = async (restaurantId, categoryName) => {
  try {
    const response = await Parse.Cloud.run('addCategory', {
      restaurantId: restaurantId,
      category: categoryName
    });
    console.log('New category added successfully:', response);
    return response;
  } catch (error) {
    console.error('Error adding new category:', error);
    throw error;
  }
};

export const updateDishAvailability = async (dishId, available) => {
  const Dish = Parse.Object.extend('Dish');
  const query = new Parse.Query(Dish);
  try {
    const dish = await query.get(dishId);
    dish.set('available', available);
    await dish.save();
  } catch (error) {
    console.error('Error updating dish availability:', error);
    throw error;
  }
};

export const updateDishVariantAvailability = async (variantId, available) => {
  const DishVariant = Parse.Object.extend('DishVariant');
  const query = new Parse.Query(DishVariant);
  try {
    const variant = await query.get(variantId);
    variant.set('available', available);
    await variant.save();
  } catch (error) {
    console.error('Error updating dish variant availability:', error);
    throw error;
  }
};

export const addMenuItem = async (restaurantId, itemData) => {
  try {
    // Primero, creamos el plato principal
    const dishResponse = await Parse.Cloud.run('createDish', {
      restaurantId: restaurantId,
      name: itemData.name,
      price: itemData.price,
      minSelectedVariants: 0,
      maxSelectedVariants: itemData.variants ? itemData.variants.length : 0,
      available: itemData.available,
      category: itemData.category,
      subcategory: itemData.subcategory || '',
      description: itemData.description,
    });

    const createdDish = dishResponse.toJSON();

    // Ahora, creamos las variantes
    if (itemData.variants && itemData.variants.length > 0) {
      const variantPromises = itemData.variants.map(variant =>
        Parse.Cloud.run('createDishVariant', {
          dishId: createdDish.objectId,
          name: variant.name,
          price: parseFloat(variant.price),
          minCount: 1,
          maxCount: 1,
          minSelectedVariants: 0,
          maxSelectedVariants: 0,
          restaurantId: restaurantId,
          available: true,
          responseAsTree: false
        })
      );

      const variantResponses = await Promise.all(variantPromises);

      // console.log('variantResponses', variantResponses);
      // createdDish.variants = variantResponses.map(response => response.toJSON());
      createdDish.variants = variantResponses;
    }

    // Si hay una imagen, la subimos usando Parse.File
    if (itemData.image) {

      const { fullFileName, base64 } = itemData.image;

      const file = new Parse.File(fullFileName, { base64 });
      await file.save();

      // Actualizamos el plato con la nueva imagen
      const Dish = Parse.Object.extend('Dish');
      const query = new Parse.Query(Dish);
      const dish = await query.get(createdDish.objectId);
      dish.set('image', file);

      await dish.save();

      createdDish.image = file.toJSON();
    }

    console.log('New menu item added successfully:', createdDish);
    return createdDish;
  } catch (error) {
    console.error('Error adding new menu item:', error);
    throw error;
  }
};

export const updateMenuItem = async (restaurantId, itemId, itemData) => {
  console.log('itemData', itemData);
  // return;
  try {
    const Dish = Parse.Object.extend('Dish');
    const query = new Parse.Query(Dish);
    const dish = await query.get(itemId);

    if (!dish) {
      throw new Error('Dish not found');
    }

    // Actualizar los campos del plato
    dish.set('name', itemData.name);
    dish.set('price', itemData.price);
    dish.set('description', itemData.description);
    dish.set('category', itemData.category);
    dish.set('subcategory', itemData.subcategory);
    dish.set('available', itemData.available);
    dish.set('quantity', itemData.quantity);
    // dish.set('minSelectedVariants', itemData.minSelectedVariants);

    // Actualizar la imagen si se proporciona una nueva
    if (itemData.image && itemData.image.base64) {
      const file = new Parse.File(itemData.image.fullFileName, { base64: itemData.image.base64 });
      await file.save();
      dish.set('image', file);
    }

    // Guardar el plato actualizado
    const updatedDish = await dish.save();

    // Actualizar variantes
    if (itemData.variants && itemData.variants.length > 0) {
      // const DishVariant = Parse.Object.extend('DishVariant');
      // const variantQuery = new Parse.Query(DishVariant);
      // variantQuery.equalTo('dish', dish);
      // const existingVariants = await variantQuery.find();

      // Crear un mapa de variantes existentes por ID
      // const existingVariantsMap = new Map(existingVariants.map(v => [v.id, v]));

      // Actualizar o crear variantes
      // const variantPromises = [];

      for (const variant of itemData.variants) {
        updateDishVariant(variant)

        if (variant.variants && variant.variants.length > 0) {
          for (const subVariant of variant.variants) {
            updateDishVariant(subVariant)
          }
        }
      }

      // const variantPromises = itemData.variants.map(async variant => {
      //   let variantObject;
      //   if (variant.objectId && existingVariantsMap.has(variant.objectId)) {
      //     // Actualizar variante existente
      //     variantObject = existingVariantsMap.get(variant.objectId);
      //     existingVariantsMap.delete(variant.objectId);
      //   } else {
      //     // Crear nueva variante
      //     variantObject = new DishVariant();
      //     variantObject.set('dish', dish);
      //   }

      //   variantObject.set('name', variant.name);
      //   variantObject.set('price', variant.price);
      //   variantObject.set('available', variant.available);
      //   variantObject.set('minCount', variant.minCount || 1);
      //   variantObject.set('maxCount', variant.maxCount || 1);
      //   variantObject.set('minSelectedVariants', variant.minSelectedVariants || 0);
      //   variantObject.set('maxSelectedVariants', variant.maxSelectedVariants || 0);

      //   return variantObject.save();
      // });

      // await Promise.all(variantPromises);

      // Eliminar variantes que ya no existen
      // const deletePromises = Array.from(existingVariantsMap.values()).map(v => v.destroy());
      // await Promise.all(deletePromises);
    }

    // Obtener el plato actualizado con todas sus relaciones
    const fullDishQuery = new Parse.Query(Dish);
    fullDishQuery.include('variants');
    const fullUpdatedDish = await fullDishQuery.get(updatedDish.id);

    return fullUpdatedDish.toJSON();
  } catch (error) {
    console.error('Error updating menu item:', error);
    throw error;
  }
};

export const deleteMenuItem = async (dishId) => {
  try {
    const response = await Parse.Cloud.run('deleteDish', { dishId });

    if (response && response == 'success') {
      return true;
    } else {
      throw new Error('Failed to delete dish');
    }
  } catch (error) {
    console.error('Error deleting menu item:', error);
    throw error;
  }
};

export const sortRestaurantCategories = async (restaurantId, categories) => {
  try {
    const response = await Parse.Cloud.run('sortRestaurantCategories', {
      restaurantId,
      categories
    });
    console.log('Categories sorted successfully:', response);
    return response;
  } catch (error) {
    console.error('Error sorting restaurant categories:', error);
    throw error;
  }
};

export const getRestaurantsForUser = async (userId) => {
  try {
    const User = Parse.Object.extend('User');
    const query = new Parse.Query(User);
    query.equalTo('objectId', userId);

    const user = await query.first();

    if (!user) {
      throw new Error('Usuario no encontrado');
    }

    const restaurantQuery = user.relation('restaurants').query();
    
    // restaurantQuery.include('accountManager');

    const restaurants = await restaurantQuery.find();

    if (!restaurants || !Array.isArray(restaurants)) {
      return [];
    }

    return restaurants;
  } catch (error) {
    console.error('Error al obtener los restaurantes del usuario:', error);
    throw error;
  }
};

export const updateRestaurant = async (restaurantId, updatedFields) => {

  // console.log('updatedFields', updatedFields);

  // return updatedFields;
  try {
    const Restaurant = Parse.Object.extend('Restaurant');
    const query = new Parse.Query(Restaurant);
    const restaurant = await query.get(restaurantId);

    if (!restaurant) {
      throw new Error('Restaurante no encontrado');
    }

    // Actualizar los campos del restaurante
    Object.keys(updatedFields).forEach(key => {
      if (key === 'restaurant_logo' || key === 'image') {
        if (updatedFields[key] && updatedFields[key].base64) {
          const file = new Parse.File(updatedFields[key].name, { base64: updatedFields[key].base64 });
          restaurant.set(key, file);
        }
      } else {
        restaurant.set(key, updatedFields[key]);
      }
    });

    // Guardar los cambios
    await restaurant.save();

    console.log('Restaurante actualizado con éxito');
    return restaurant.toJSON();
  } catch (error) {
    console.error('Error al actualizar el restaurante:', error);
    throw error;
  }
};

export const updateDishVariant = async (variant) => {
  try {
    const dishResponse = await Parse.Cloud.run('saveDishVariants', {
      dishId: variant.dish.objectId,
      responseAsHierarchy: true,
      variants: [
        {
          maxCount: variant.maxCount,
          minCount: variant.minCount,
          minSelectedVariants: variant.minSelectedVariants,
          maxSelectedVariants: variant.maxSelectedVariants,
          name: variant.name,
          price: variant.price,
          available: variant.available,
          objectId: variant.objectId,
        }
      ]
    });

    return dishResponse;
  } catch (error) {
    console.error('Error al actualizar la variante del plato:', error);
    throw error;
  }
};

export const toggleDishAvailability = async (dishId, restaurantId) => {
  try {
    const dishResponse = await Parse.Cloud.run('toggleDishAvailability', {
      dishId,
      restaurantId
    });

    return dishResponse;
  } catch (error) {
    console.error('Error al actualizar la variante del plato:', error);
    throw error;
  }
}

export const uploadDocument = async (documentCode, image, userId) => {
  try {
    const uploadResponse = await Parse.Cloud.run('uploadDocumentForUser', {
      documentCode,
      image,
      userId: userId
    });

    return uploadResponse;
  } catch (error) {
    console.error('Error al subir el documento:', error);
    throw error;
  }
};

export const fetchDocuments = async (userId) => {
  try {
    const documents = await Parse.Cloud.run('fetchUserDocuments', {
      userId
    });

    return documents;
  } catch (error) {
    console.error('Error al obtener los documentos:', error);
    throw error;
  }
}

export const setExpirationDateForDocument = async (documentId, expirationDate) => {
  try {
    const document = await Parse.Cloud.run('setExpirationDateForDocument', {
      id: documentId,
      expirationDate
    });
    
  } catch (error) {
    console.error('Error al actualizar la fecha de expiración del documento:', error);
    throw error;
  }
}

export const deleteDocumentForUser = async (documentId) => {
  try {
    const document = await Parse.Cloud.run('deleteDocumentForUser', {
      id: documentId
    });
    
  } catch (error) {
    console.error('Error al actualizar la fecha de expiración del documento:', error);
    throw error;
  }
}