// Sidebar.js
import React, { useContext } from 'react';
import Parse from 'parse';
import { AuthContext } from '../contexts/AuthContext';
import { StyleSheet, Text, View, Image, Pressable, ScrollView, Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import Logo from '../assets/logo.svg';

const SidebarItem = ({ label, icon, isActive, onPress, isCollapsed }) => (
  <Pressable
    style={[
      styles.item,
      isActive && styles.activeItem,
      isCollapsed && styles.collapsedItem
    ]}
    onPress={onPress}
  >
    <Icon
      name={icon}
      size={isCollapsed ? 20 : 24}
      color={isActive ? colors.primary : '#6C7880'}
    />
    <Text
      style={[
        styles.itemText,
        {
          color: isActive ? colors.black : '#6C7880',
          fontWeight: isActive ? '500' : 'normal'
        },
        isCollapsed && styles.collapsedItemText
      ]}
    >
      {isCollapsed ? label.split(' ')[0] : label}
    </Text>
  </Pressable>
);

export default function Sidebar({ page, setPage, isCollapsed, setIsCollapsed }) {
  const { user, setUser, restaurant, setRestaurant } = useContext(AuthContext);

  // Function to get the logo URL
  const getLogoUrl = () => {
    if (restaurant) {
      return restaurant.get('restaurant_logo')?.url() ||
        restaurant.get('image')?.url() ||
        restaurant.get('imageSrc')?.url() ||
        null; // Fallback if none of the fields exist
    }
    return null;
  };

  const onLogout = async () => {
    try {
      await Parse.User.logOut();
      setUser(null);
      setRestaurant(null);
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <View style={[styles.container, isCollapsed && styles.collapsedContainer]}>
      <Pressable style={[styles.collapseButton, isCollapsed && styles.collapsedIcon]} onPress={() => setIsCollapsed(!isCollapsed)}>
        <Icon name={isCollapsed ? 'chevron-right' : 'chevron-left'} size={24} color={colors.white} />
      </Pressable>
      <View style={styles.logoContainer}>
        <Image source={Logo} style={[styles.logo, isCollapsed && styles.collapsedLogo]} resizeMode="contain" />
      </View>
      <View style={styles.contentContainer}>
        <ScrollView style={styles.scrollView} showsVerticalScrollIndicator={false}>
          <SidebarItem
            label="Dashboard"
            icon="dashboard"
            isActive={page === 'Dashboard'}
            onPress={() => setPage('Dashboard')}
            isCollapsed={isCollapsed}
          />
          <SidebarItem
            label="Reports"
            icon="list"
            isActive={page === 'Reports'}
            onPress={() => setPage('Reports')}
            isCollapsed={isCollapsed}
          />
          <SidebarItem
            label="Menu"
            icon="restaurant-menu"
            isActive={page === 'Menu'}
            onPress={() => setPage('Menu')}
            isCollapsed={isCollapsed}
          />
          {/* <SidebarItem
            label="Reviews"
            icon="star"
            isActive={page === 'Reviews'}
            onPress={() => setPage('Reviews')}
            isCollapsed={isCollapsed}
          /> */}
          {/* <SidebarItem
            label="Dispatchers"
            icon="local-shipping"
            isActive={page === 'Dispatchers'}
            onPress={() => setPage('Dispatchers')}
            isCollapsed={isCollapsed}
          /> */}
          {/* <SidebarItem
            label="Marketing"
            icon="campaign"
            isActive={page === 'Marketing'}
            onPress={() => setPage('Marketing')}
            isCollapsed={isCollapsed}
          /> */}
          {/* <SidebarItem
            label="Support Chat"
            icon="chat"
            isActive={page === 'Support'}
            onPress={() => setPage('Support')}
            isCollapsed={isCollapsed}
          /> */}
          {/* <SidebarItem
            label="Store Management"
            icon="store"
            isActive={page === 'StoreManagement'}
            onPress={() => setPage('StoreManagement')}
            isCollapsed={isCollapsed}
          /> */}
          {/* <SidebarItem
            label="Bank Details"
            icon="account-balance"
            isActive={page === 'BankDetails'}
            onPress={() => setPage('BankDetails')}
            isCollapsed={isCollapsed}
          /> */}
          {/* <SidebarItem
            label="Payments"
            icon="payments"
            isActive={page === 'Payments'}
            onPress={() => setPage('Payments')}
            isCollapsed={isCollapsed}
          /> */}
          <SidebarItem
            label="Settings"
            icon="settings"
            isActive={page === 'Settings'}
            onPress={() => setPage('Settings')}
            isCollapsed={isCollapsed}
          />
        </ScrollView>
      </View>
      <View style={styles.bottomContainer}>
        {!isCollapsed && (
          <>
            <View style={styles.userInfo}>
              <Image source={{ uri: getLogoUrl() }} style={styles.avatar} />
              <View style={styles.userTextContainer}>
                <Text style={styles.userName}>{user?.get('username')}</Text>
                <Text style={styles.userEmail} numberOfLines={1} ellipsizeMode="tail">
                  {user?.get('email')}
                </Text>
              </View>
            </View>
            <Pressable style={styles.logoutButton} onPress={onLogout}>
              <Icon name="exit-to-app" size={24} color={colors.white} />
              <Text style={styles.logoutText}>Sign Out</Text>
            </Pressable>
          </>
        )}
        {isCollapsed && (
          <Pressable style={styles.collapsedLogoutButton} onPress={onLogout}>
            <Icon name="exit-to-app" size={20} color={colors.primary} />
            <Text style={styles.collapsedLogoutText}>Exit</Text>
          </Pressable>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 240,
    backgroundColor: 'rgba(223, 234, 242, 1)',
    borderRightColor: colors.lightGrey,
    height: '100%', // Change to 100% height
    flexDirection: 'column',
    position: 'fixed', // Add fixed positioning
    left: 0, // Align to the left
    top: 0, // Align to the top
    bottom: 0, // Stretch to the bottom
    zIndex: 1000, // Ensure it's above other content
  },
  collapsedContainer: {
    width: 80,
  },
  collapseButton: {
    position: 'absolute',
    top: 10,
    right: 0,
    zIndex: 1,
    width: 30,
    height: 30,
    backgroundColor: colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    padding: 20,
    // borderBottomWidth: 1,
    // borderBottomColor: colors.lightGrey,
    alignItems: 'center',
  },
  logo: {
    width: 90,
    height: 30,
    position: 'relative',
    // top: 10,
  },
  collapsedLogo: {
    width: 30,
    height: 30,
  },
  contentContainer: {
    flex: 1,
  },
  scrollView: {
    flexGrow: 0,
  },
  bottomContainer: {
    borderTopWidth: 1,
    borderTopColor: colors.lightGrey,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  collapsedItem: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
  },
  itemText: {
    marginLeft: 15,
    fontSize: 16,
  },
  collapsedItemText: {
    marginLeft: 0,
    marginTop: 5,
    fontSize: 10,
    color: colors.black,
    textAlign: 'center',
  },
  activeItem: {
    backgroundColor: colors.lightBackground,
    borderLeftColor: colors.primary,
    borderLeftWidth: 3,
  },
  userInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  avatar: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  userTextContainer: {
    marginLeft: 10,
    flex: 1, // Add this to allow text container to shrink
  },
  userName: {
    fontWeight: 'bold',
    color: colors.black,
    fontSize: 14, // Reduce font size
  },
  userEmail: {
    fontSize: 12,
    color: colors.black,
    fontWeight: 'bold',
  },
  logoutButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.primary,
    padding: 15,
  },
  logoutText: {
    color: colors.white,
    marginLeft: 10,
  },
  collapsedLogoutButton: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  collapsedLogoutText: {
    fontSize: 10,
    color: colors.black,
    marginTop: 5,
  },
  restaurantName: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 4,
    color: colors.primary,
  },
  collapsedIcon: {
    width: 20,
    height: 20,
  },
});