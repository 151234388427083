import React, { useState, useRef, useEffect } from 'react';
import { Modal, View, Text, TextInput, Pressable, ScrollView, StyleSheet, Animated, ActivityIndicator } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import Icon from 'react-native-vector-icons/MaterialIcons';
import * as ImagePicker from 'expo-image-picker';
import colors from '../constants/colors';
import ImageSelector from './ImageSelector.js';

const MODAL_WIDTH = 600;

export default function AddMenuItemModal({ isVisible, onClose, onSave, categories, isSaving }) {
  const [name, setName] = useState('');
  const [price, setPrice] = useState('');
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('');
  const [variants, setVariants] = useState([]);
  const [image, setImage] = useState(null);

  const slideAnim = useRef(new Animated.Value(MODAL_WIDTH)).current;
  const fadeAnim = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    if (isVisible) {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(slideAnim, {
          toValue: MODAL_WIDTH,
          duration: 300,
          useNativeDriver: true,
        }),
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 300,
          useNativeDriver: true,
        }),
      ]).start();
    }
  }, [isVisible]);

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      const asset = result.assets[0];

      // Determinar la extensión basada en el tipo de datos en base64
      let extension = 'jpg'; // Por defecto a jpg
      if (asset.base64.startsWith('iVBORw0KGgo')) {
        extension = 'png';
      } else if (asset.base64.startsWith('R0lGODlh')) {
        extension = 'gif';
      }

      // Generar un nombre de archivo único
      const fileName = `image_${Date.now()}`;
      const fullFileName = `${fileName}.${extension}`;

      setImage({
        base64: asset.base64,
        fileName: fileName,
        extension: extension,
        fullFileName: fullFileName,
      });
    }
  };

  const handleSave = () => {
    const newItem = {
      name,
      price: parseFloat(price),
      description,
      category,
      quantity: quantity ? parseFloat(quantity) : null,
      available: true,
      variants,
      image: image ? {
        uri: image.uri,
        blob: image.blob,
        fileName: image.fileName,
        fullFileName: image.fullFileName,
        extension: image.extension,
        base64: image.base64
      } : null
    };
    onSave(newItem);
  };

  const addVariant = () => {
    setVariants([...variants, { name: '', price: '' }]);
  };

  const updateVariant = (index, field, value) => {
    const updatedVariants = [...variants];
    updatedVariants[index][field] = value;
    setVariants(updatedVariants);
  };

  const removeVariant = (index) => {
    const updatedVariants = variants.filter((_, i) => i !== index);
    setVariants(updatedVariants);
  };

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="none"
      onRequestClose={onClose}
    >
      <Animated.View
        style={[
          styles.modalOverlay,
          {
            opacity: fadeAnim,
          },
        ]}
      >
        <Pressable style={styles.modalOverlayPressable} onPress={onClose} />
        <Animated.View
          style={[
            styles.modalContent,
            {
              transform: [{ translateX: slideAnim }],
            },
          ]}
        >
          <View style={styles.modalHeader}>
            <Text style={styles.modalTitle}>Agregar Nuevo Elemento del Menú</Text>
            <Pressable onPress={onClose}>
              <Icon name="close" size={24} color={colors.darkGrey} />
            </Pressable>
          </View>
          <ScrollView style={styles.modalBody}>
            <ImageSelector image={image} onPress={pickImage} />
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Name</Text>
              <TextInput
                style={styles.input}
                value={name}
                onChangeText={setName}
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Description</Text>
              <TextInput
                style={[styles.input, styles.multilineInput]}
                value={description}
                onChangeText={setDescription}
                placeholder="Descripción del elemento"
                multiline
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Categoría</Text>
              <Picker
                selectedValue={category}
                onValueChange={(itemValue) => setCategory(itemValue)}
                style={styles.picker}
              >
                <Picker.Item label="Seleccionar categoría" value="" />
                {categories.map((cat) => (
                  <Picker.Item key={cat} label={cat} value={cat} />
                ))}
              </Picker>
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Price</Text>
              <TextInput
                style={styles.input}
                value={price}
                onChangeText={setPrice}
                keyboardType="numeric"
              />
            </View>
            <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Quantity</Text>
              <TextInput
                style={styles.input}
                value={quantity}
                onChangeText={setQuantity}
                keyboardType="numeric"
              />
            </View>
            {/* <View style={styles.inputGroup}>
              <Text style={styles.inputLabel}>Variantes</Text>
              {variants.map((variant, index) => (
                <View key={index} style={styles.variantContainer}>
                  <TextInput
                    style={[styles.input, styles.variantInput]}
                    value={variant.name}
                    onChangeText={(text) => updateVariant(index, 'name', text)}
                    placeholder="Nombre de la variante"
                  />
                  <TextInput
                    style={[styles.input, styles.variantInput]}
                    value={variant.price}
                    onChangeText={(text) => updateVariant(index, 'price', text)}
                    placeholder="Precio"
                    keyboardType="numeric"
                  />
                  <Pressable onPress={() => removeVariant(index)}>
                    <Icon name="remove-circle" size={24} color={colors.danger} />
                  </Pressable>
                </View>
              ))}
              <Pressable style={styles.addVariantButton} onPress={addVariant}>
                <Text style={styles.addVariantButtonText}>+ Agregar Variante</Text>
              </Pressable>
            </View> */}
          </ScrollView>
          <View style={styles.modalFooter}>
            <Pressable style={styles.cancelButton} onPress={onClose} disabled={isSaving}>
              <Text style={styles.cancelButtonText}>Cancelar</Text>
            </Pressable>
            <Pressable style={styles.saveButton} onPress={handleSave} disabled={isSaving}>
              {isSaving ? (
                <ActivityIndicator color={colors.white} />
              ) : (
                <Text style={styles.saveButtonText}>Guardar</Text>
              )}
            </Pressable>
          </View>
          {isSaving && (
            <View style={styles.loadingOverlay}>
              <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color={colors.primary} />
                <Text style={styles.loadingText}>Guardando...</Text>
              </View>
            </View>
          )}
        </Animated.View>
      </Animated.View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalOverlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  modalOverlayPressable: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  modalContent: {
    backgroundColor: colors.white,
    width: MODAL_WIDTH,
    height: '100%',
    padding: 20,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  modalTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  modalBody: {
    flex: 1,
  },
  inputGroup: {
    marginBottom: 16,
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 8,
  },
  input: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
    padding: 8,
  },
  multilineInput: {
    height: 100,
    textAlignVertical: 'top',
  },
  picker: {
    borderWidth: 1,
    borderColor: colors.lightGrey,
    borderRadius: 4,
  },
  variantContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 8,
  },
  variantInput: {
    flex: 1,
    marginRight: 8,
  },
  addVariantButton: {
    backgroundColor: colors.primary,
    padding: 8,
    borderRadius: 4,
    alignItems: 'center',
  },
  addVariantButtonText: {
    color: colors.white,
    fontWeight: 'bold',
  },
  modalFooter: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
  cancelButton: {
    backgroundColor: colors.lightGrey,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
    marginRight: 8,
  },
  cancelButtonText: {
    color: colors.darkGrey,
  },
  saveButton: {
    backgroundColor: colors.primary,
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 4,
  },
  saveButtonText: {
    color: colors.white,
  },
  loadingOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingContainer: {
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 10,
    alignItems: 'center',
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: colors.darkGrey,
  },
});