import React, { useState, useEffect, useContext } from 'react';
import { ScrollView, View, StyleSheet, Text, Dimensions, Image, SafeAreaView, Pressable, TextInput } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { AuthContext } from '../contexts/AuthContext';
import OpeningHours from './OpeningHours';
import colors from '../constants/colors';
import { updateRestaurant, fetchDocuments } from '../services/api/restaurant';
import * as ImagePicker from 'expo-image-picker';
import FileUploader from './FileUploader';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import Document from './Document';

const daysOfWeek = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

const { width } = Dimensions.get('window');

const isMobile = width < 768;

export default function Settings() {
  const { restaurant } = useContext(AuthContext) || {};
  const [isModified, setIsModified] = useState(false);
  const [schedule, setSchedule] = useState([]);

  // Nuevos estados para los campos del formulario
  const [restaurantName, setRestaurantName] = useState('');
  const [contactAddress, setContactAddress] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPhone, setContactPhone] = useState('');
  const [aboutUs, setAboutUs] = useState('');
  const [logoImage, setLogoImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [merchantAgreement, setMerchantAgreement] = useState(null);
  const [otherDocument, setOtherDocument] = useState(null);
  const [merchantAgreementDate, setMerchantAgreementDate] = useState(null);
  const [otherDocumentDate, setOtherDocumentDate] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [merchantAgreementSaved, setMerchantAgreementSaved] = useState([]);
  const [otherDocumentSaved, setOtherDocumentSaved] = useState([]);
  const [focus, setFocus] = useState(false);


  useEffect(() => {
    if (restaurant) {
      // console.log('restaurant', restaurant);
      setRestaurantName(restaurant.get('restaurant_name') || '');
      setContactAddress(restaurant.get('contact_address') || '');
      setContactEmail(restaurant.get('contact_email') || '');
      setContactPhone(restaurant.get('contact_phone') || '');
      setAboutUs(restaurant.get('restaurant_about_us') || '');

      if (restaurant.get('schedule')) {
        const parsedSchedule = JSON.parse(restaurant.get('schedule'));
        // console.log('parsedSchedule', parsedSchedule);
        const formattedSchedule = formatSchedule(parsedSchedule);
        setSchedule(formattedSchedule);
      }

      fetchDocuments(restaurant.id).then((documents) => {
        console.log('documents', documents);

        if (documents.length > 0) {
          for (const document of documents) {
            if (document.get('documentCode') === 'merchantAgreement') {
              setMerchantAgreementSaved([...merchantAgreementSaved, document]);
            }

            if (document.get('documentCode') === 'other') {
              setOtherDocumentSaved([...otherDocumentSaved, document]);
            }
          }
        }
      });
    }
  }, [restaurant]);

  useEffect(() => {
    console.log('merchantAgreement', merchantAgreement);
    console.log('otherDocument', otherDocument);
  }, [merchantAgreement, otherDocument]);

  const formatSchedule = (scheduleObj) => {
    return Object.entries(scheduleObj).map(([key, value]) => {
      const dayIndex = parseInt(key) - 1;
      // Check if value[0] exists and has start and end properties
      if (value && value[0] && value[0].start && value[0].end) {
        const { start, end } = value[0];
        return {
          day: daysOfWeek[dayIndex],
          open: formatTime(start),
          close: formatTime(end)
        };
      } else {
        // Return a default object if the expected structure is not present
        return {
          day: daysOfWeek[dayIndex],
          open: 'Closed',
          close: 'Closed'
        };
      }
    });
  };

  const formatTime = (time) => {
    const hours = time.slice(0, 2);
    const minutes = time.slice(2);
    return `${hours}:${minutes}`;
  };

  const handleInputChange = (setter) => (value) => {
    setter(value);
    setIsModified(true);
  };

  const pickImage = async (setImageFunction) => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      base64: true,
    });

    if (!result.canceled) {
      const asset = result.assets[0];
      setImageFunction({
        base64: asset.base64,
        uri: asset.uri,
      });
      setIsModified(true);
    }
  };

  const handleLogoChange = () => pickImage(setLogoImage);
  const handleCoverChange = () => pickImage(setCoverImage);

  const handleSubmit = async () => {
    if (restaurant && isModified) {
      try {
        const updatedFields = {
          restaurant_name: restaurantName,
          contact_address: contactAddress,
          contact_email: contactEmail,
          contact_phone: contactPhone,
          restaurant_about_us: aboutUs,
          // schedule: JSON.stringify(schedule)
        };

        if (logoImage) {
          updatedFields.restaurant_logo = {
            base64: logoImage.base64,
            name: 'restaurant_logo.jpg'
          };
        }

        if (coverImage) {
          updatedFields.image = {
            base64: coverImage.base64,
            name: 'restaurant_cover.jpg'
          };
        }

        await updateRestaurant(restaurant.id, updatedFields);
        setIsModified(false);
        alert('Los cambios se han guardado correctamente');
      } catch (error) {
        console.error('Error al guardar los cambios:', error);
        alert('Hubo un error al guardar los cambios. Por favor, inténtalo de nuevo.');
      }
    }
  };

  const getImageUrl = (key) => {
    if (restaurant && restaurant.get(key) && restaurant.get(key).url) {
      return restaurant.get(key).url();
    }
    return '';
  };

  if (!restaurant) {
    return <Text>Cargando...</Text>;
  }

  return (
    <SafeAreaView style={styles.safeArea}>
      <ScrollView contentContainerStyle={styles.scrollViewContent}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.headerText}>Merchant Profile</Text>
          </View>
          <View style={[styles.form, { marginTop: 30 }]}>
            <View style={styles.section}>
              <View style={styles.inputContainer}>
                <View style={{ width: 400 }}>
                  <View>
                    <Text style={styles.sectionTitle}>Restaurant Logo</Text>
                  </View>
                  <View style={{ marginVertical: 15 }}>
                    <Pressable onPress={handleLogoChange}>
                      <Text style={{ color: colors.primary, fontSize: 16 }}>Change</Text>
                    </Pressable>
                  </View>
                </View>
                <View style={styles.sectionInputs}>
                  <Image
                    source={{ uri: logoImage ? logoImage.uri : getImageUrl('restaurant_logo') }}
                    style={styles.logo}
                  />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View style={{ width: 400 }}>
                  <View>
                    <Text style={styles.sectionTitle}>Display Photo</Text>
                    <Text style={styles.sectionSubtitle}>This photo will be displayed on your menu</Text>
                  </View>
                  <View style={{ marginVertical: 15 }}>
                    <Pressable onPress={handleCoverChange}>
                      <Text style={{ color: colors.primary, fontSize: 16 }}>Change</Text>
                    </Pressable>
                  </View>
                </View>
                <View style={styles.sectionInputs}>
                  <Image
                    source={{ uri: coverImage ? coverImage.uri : getImageUrl('image') }}
                    style={styles.cover}
                  />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View style={{ width: 400, marginBottom: 5 }}>
                  <Text style={styles.sectionTitle}>Merchant Name</Text>
                </View>
                <View style={styles.sectionInputs}>
                  <TextInput
                    value={restaurantName}
                    onChangeText={handleInputChange(setRestaurantName)}
                    style={styles.input}
                  />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View style={{ width: 400, marginBottom: 5 }}>
                  <Text style={styles.sectionTitle}>Location</Text>
                </View>
                <View style={styles.sectionInputs}>
                  <TextInput
                    value={contactAddress}
                    onChangeText={handleInputChange(setContactAddress)}
                    style={styles.input}
                  />
                  <Icon name="location-pin" size={24} color={colors.mediumGrey} style={styles.iconLocation} />
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View style={{ width: 400, marginBottom: 5 }}>
                  <Text style={styles.sectionTitle}>Contact</Text>
                </View>
                <View style={[styles.sectionInputs, { flexDirection: 'row', justifyContent: 'space-between', width: '100%' }]}>
                  <View style={{ width: '45%' }}>
                    <TextInput
                      value={contactEmail}
                      onChangeText={handleInputChange(setContactEmail)}
                      style={[styles.input, { width: '100%' }]}
                    />
                  </View>
                  <View style={{ marginLeft: 10, width: '45%' }}>
                    <TextInput
                      value={contactPhone}
                      onChangeText={handleInputChange(setContactPhone)}
                      style={[styles.input, { width: '100%' }]}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.inputContainer}>
                <View style={{ width: 400 }}>
                  <Text style={styles.sectionTitle}>Description</Text>
                  <Text style={styles.sectionSubtitle}>Tell our clients why your business is special in under 140 characters.</Text>
                </View>
                <View style={styles.sectionInputs}>
                  <TextInput
                    multiline
                    numberOfLines={4}
                    value={aboutUs}
                    onChangeText={handleInputChange(setAboutUs)}
                    style={styles.textarea}
                    maxLength={140}
                  />
                </View>
              </View>
            </View>

            <View style={[styles.section, { flexDirection: 'row', zIndex: 10 }]}>
              {merchantAgreementSaved.length > 0 && merchantAgreementSaved.map((document) => <Document document={document} key={document.id} />)}

              {merchantAgreementSaved.length === 0 && (
                <View style={{ marginLeft: 10 }}>
                  <FileUploader
                    title="Merchant Agreement"
                    documentType="merchantAgreement"
                    onFileSelect={(file) => setMerchantAgreement(file)}
                  />
                </View>
              )}
            </View>

            <View style={[styles.section, { flexDirection: 'row', zIndex: 9 }]}>
              {otherDocumentSaved.length > 0 && otherDocumentSaved.map((document) => <Document document={document} key={document.id} />)}

              <View style={{ marginLeft: 10 }}>
                <FileUploader
                  title="Documents"
                  documentType="other"
                  onFileSelect={(file) => setOtherDocument(file)}
                />
              </View>
            </View>

            {/* {schedule && schedule.length > 0 && (
              <OpeningHours
                schedule={schedule}
                handleScheduleChange={(newSchedule) => {
                  setSchedule(newSchedule);
                  setIsModified(true);
                }}
              />
            )} */}

            {restaurant.get('accountManager') && (
              <View style={styles.inputContainer}>
                <View style={{ width: 400 }}>
                  <View>
                    <Text style={styles.sectionTitle}>Account Manager</Text>
                  </View>
                </View>
                <View style={[styles.sectionInputs, { flexDirection: 'row' }]}>
                  <Text>{restaurant.get('accountManager').get('full_name')}</Text>
                  <Text style={{ color: colors.primary, fontWeight: 'bold' }}> - </Text>
                  <Text>{restaurant.get('accountManager').get('phone')}</Text>
                  <Text style={{ color: colors.primary, fontWeight: 'bold' }}> - </Text>
                  <Text>{restaurant.get('accountManager').get('username')}</Text>
                </View>
              </View>
            )}

            <View>
              <Pressable
                disabled={!isModified}
                style={[styles.button, !isModified && styles.buttonDisabled]}
                onPress={handleSubmit}
              >
                <Text style={styles.buttonText}>Save Changes</Text>
              </Pressable>
            </View>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

const windowHeight = Dimensions.get('window').height;

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
    backgroundColor: colors.background, // Use your app's background color
  },
  scrollViewContent: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    padding: 20,
    minHeight: windowHeight,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 16,
    alignItems: 'center',
  },
  headerText: {
    fontSize: 25,
    fontWeight: 'bold',
    color: colors.darkGrey,
    marginHorizontal: 10,
  },
  form: {
    flex: 1,
  },
  section: {
    marginBottom: 20,
    width: isMobile ? '100%' : '80%',
  },
  cover: {
    width: isMobile ? '100%' : 480,
    height: 210,
    borderRadius: 8,
  },
  input: {
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 8,
    // margin: 10,
    padding: 10,
  },
  textarea: {
    height: 100,
    borderColor: colors.lightGrey,
    borderWidth: 1,
    borderRadius: 8,
    // margin: 10,
    padding: 10,
  },
  inputContainer: {
    marginBottom: 20,
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
    // marginBottom: 10,
    flex: 1,
  },
  sectionInputs: {
    flex: 1,
    position: 'relative',
  },
  checkboxContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  checkboxLabel: {
    marginLeft: 10,
  },
  button: {
    backgroundColor: '#6C7880',
    color: '#fff',
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    width: 200,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDisabled: {
    backgroundColor: colors.lightGrey,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  logo: {
    width: 160,
    height: 160,
    marginBottom: 10,
    borderRadius: 8,
  },
  sectionSubtitle: {
    fontSize: 12,
    color: '#909090',
    marginTop: 10,
    marginBottom: 5,
  },
  iconLocation: {
    position: 'absolute',
    right: 0,
    top: 5,
    color: colors.primary,
  },
  uploadArea: {
    borderWidth: 2,
    borderColor: colors.lightGrey,
    borderStyle: 'dashed',
    borderRadius: 8,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-all',
    marginTop: 10,
    marginBottom: 20,
  }
});