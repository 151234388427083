import React, { useState } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Platform, Dimensions } from 'react-native';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './CustomDateRangePicker.css';
import moment from 'moment';
import { Ionicons } from '@expo/vector-icons';
import colors from '../../../constants/colors';
import { isSameDay } from '../../../utils/utils';

const { width } = Dimensions.get('window');
const isMobile = width < 768;

export default function CustomDateRangePicker({ startDate, endDate, onDatesChange }) {
  const [focusedInput, setFocusedInput] = useState(null);

  const handleDatesChange = ({ startDate, endDate }) => {
    onDatesChange({
      startDate: startDate ? moment(startDate).toDate() : null,
      endDate: endDate ? moment(endDate).toDate() : null
    });
  };

  const formatDateRange = () => {
    const start = startDate ? moment(startDate).format('MM/DD/YYYY') : '';
    const end = endDate ? moment(endDate).format('MM/DD/YYYY') : '';
    return `${start} - ${end}`;
  };

  const today = moment();

  const presets = [
    // {
    //   text: "Last Year",
    //   start: moment()
    //     .subtract(1, "years")
    //     .startOf("year"),
    //   end: moment()
    //     .subtract(1, "years")
    //     .endOf("year"),
    // },
    {
      text: "This Year",
      start: moment().startOf("year"),
      end: moment().endOf("year"),
    },
    {
      text: "Last Month",
      start: moment()
        .subtract(1, "month")
        .startOf("month"),
      end: moment()
        .subtract(1, "month")
        .endOf("month"),
    },
    {
      text: "Last Week",
      start: moment()
        .subtract(1, "weeks")
        .startOf("isoWeek"),
      end: moment()
        .subtract(1, "weeks")
        .endOf("isoWeek"),
    },
    {
      text: "This Month",
      start: moment().startOf("month"),
      end: moment().endOf("month"),
    },
    {
      text: "This Week",
      start: moment().startOf("week"),
      end: moment().endOf("week"),
    }
  ];

  const renderDatePresets = () => {
    return (
      <div className="PresetDateRangePicker_panel">
        {presets.map(({ text, start, end }) => {
          const isSelected =
            isSameDay(start, startDate) && isSameDay(end, endDate);
          return (
            <button
              key={text}
              type="button"
              onClick={() => handlePresetClick(start, end)}
              className={`PresetDateRangePicker_button ${
                isSelected ? "selected" : ""
              }`}
              style={{ marginTop: "5px" }}
            >
              {text}
            </button>
          );
        })}
      </div>
    );
  };

  const handlePresetClick = (start, end) => {
    handleDatesChange({
      startDate: start.toDate(),
      endDate: end.toDate()
    });
  };

  return (
    <View style={styles.container}>
      {/* <TouchableOpacity
        style={styles.button}
        onPress={() => setFocusedInput('startDate')}
      >
        <Text style={styles.buttonText}>{formatDateRange()}</Text>
        <Ionicons name="calendar-outline" size={24} color={colors.primary} />
      </TouchableOpacity> */}

      <View style={[styles.containerPicker]}>
        <DateRangePicker
          startDateId="rangePicker"
          endDateId="rangePicker"
          startDate={startDate ? moment(startDate) : null}
          endDate={endDate ? moment(endDate) : null}
          onDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          onFocusChange={setFocusedInput}
          numberOfMonths={isMobile ? 1 : 2}
          isOutsideRange={() => false}
          minimumNights={0}
          renderCalendarInfo={renderDatePresets}
        />

        <Ionicons name="calendar-outline" size={16} color={colors.black} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: colors.lightGray,
    borderRadius: 5,
    padding: 10,
  },
  buttonText: {
    fontSize: 16,
    color: colors.darkGray,
  },
  containerPicker: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 5,
    width: isMobile ? 300 : 'auto',
  },
});