// orders.js
import Parse from 'parse';

// Base function for querying orders
const queryOrders = async (restaurantId, startDate, endDate, fields, limit, status, includeRefunds = false, additionalSetup = () => {}) => {
    const Order = Parse.Object.extend('Order');
    const query = new Parse.Query(Order);

    query.equalTo('restaurant', Parse.Object.extend('Restaurant').createWithoutData(restaurantId));
    
    if (startDate) {
        query.greaterThanOrEqualTo('createdAt', new Date(startDate));
    }
    
    if (endDate) {
        query.lessThanOrEqualTo('createdAt', new Date(endDate));
    }

    query.select(...fields);

    query.include('user');
    
    query.include('restaurant');
    
    query.include('driver');
    
    if (status.length > 0) {
        query.containedIn('status', status);
    }

    query.limit(limit);
    
    query.descending('createdAt');

    // Apply any additional setup
    additionalSetup(query);

    try {
        let results = await query.find();
        
        if (includeRefunds) {
            results = await Promise.all(results.map(async (order) => {
                const orderJSON = order.toJSON();
                orderJSON.refund = null;
                
                const refundClass = Parse.Object.extend('Refund');
                const refundQuery = new Parse.Query(refundClass);

                refundQuery.equalTo('order', order);
                refundQuery.select('taxRefund');
                const refunds = await refundQuery.first();

                if (refunds) {
                    orderJSON.refund = refunds.toJSON();
                }

                // if (orderJSON.objectId === 'Ej2gkWoFEv') {
                //     console.log('orderJSON:', orderJSON);
                // }

                // orderJSON.refunds = refunds.map(refund => ({
                //     taxRefund: refund.get('taxRefund')
                // }));
                
                return orderJSON;
            }));
        } else {
            results = results.map(order => order.toJSON());
        }

        return results;
    } catch (error) {
        console.error('Error fetching orders:', error);
        throw error;
    }
};

export const getOrders = async (
    restaurantId, 
    startDate, 
    endDate, 
    fields = ['objectId', 'taxData', 'dishes', 'restaurant', 'user', 'driver', 'orderNumber', 'total', 'status', 'contactPhone', 'subtotals', 'createdAt', 'updatedAt', 'restaurantSubtotals'],
    limit = 1000,
    status = [],
    includeRefunds = false
) => {
    return queryOrders(restaurantId, startDate, endDate, fields, limit, status, includeRefunds);
};

export const getReviews = async (
    restaurantId,
    startDate,
    endDate,
    fields = ['objectId', 'review', 'user.full_name', 'createdAt'],
    limit = 100
) => {
    try {
        const response = await Parse.Cloud.run('fetchReviewsByFilter', {
            page: 0,
            limit: 10000,
            filter: {
                restaurantId,
                startDate,
                endDate
            }
        });
        return response.items;
    } catch (error) {
        console.error('Error fetching reviews:', error);
        throw error;
    }
};

export const restaurantLogin = async (username, password) => {
    const response = await Parse.Cloud.run('restaurantLogin', { username, password });
    return response;
};


export const getSalesData = async (restaurantId, startDate, endDate, fields = ['date', 'total']) => {
  const Order = Parse.Object.extend('Order');
  const query = new Parse.Query(Order);
  
  query.equalTo('restaurant', Parse.Object.extend('Restaurant').createWithoutData(restaurantId));
  query.greaterThanOrEqualTo('createdAt', new Date(startDate));
  query.lessThanOrEqualTo('createdAt', new Date(endDate));
  query.equalTo('status', 'DELIVERED');
  
  query.select(...fields);
  query.limit(1000); // Adjust this based on your needs and Parse Server limitations
  
  const results = await query.find();
  
  // Process the results into daily data
//   console.log('results:', results);
  const salesData = results.map(order => ({
    // Esta línea extrae la fecha de creación del pedido, 
    // la convierte a formato ISO y luego la recorta para obtener solo la parte de la fecha (AAAA-MM-DD)
    date: order.get('createdAt').toISOString().split('T')[0], // YYYY-MM-DD
    // total: order.get('total') / 100 // Convert cents to dollars
    total: order.get('restaurantSubtotals')
  }));
  
  // Sort the data by date
  salesData.sort((a, b) => new Date(a.date) - new Date(b.date));
  
  return salesData;
};

export const subscribeToNewOrders = (restaurantId, onNewOrder) => {
    const Order = Parse.Object.extend('Order');
    const Restaurant = Parse.Object.extend('Restaurant');
    const restaurant = new Restaurant();
    restaurant.id = restaurantId;
  
    const query = new Parse.Query(Order);
    query.equalTo('restaurant', restaurant);
  
    const liveQueryClient = new Parse.LiveQueryClient({
        applicationId: 'rifBVbTe6ZkO8DXefo8onAavO3mgmb7nzqai55uI',
        serverURL: 'wss://pg-app-fpknvyifctuxd3evb1elz0acvdijxe.scalabl.cloud/1/',
        javascriptKey: 'z18zu1sVDQCTl7NtzKmfCvEZQVFhyEWQwmvJQR8q',
    });
    liveQueryClient.open();

    // if subscription succeeds console log success message
    liveQueryClient.on('open', () => {
        console.log('subscription opened');
    });
    const subscription = liveQueryClient.subscribe(query);
    subscription.on('create', onNewOrder);
  
    
    return subscription; // You can use this to unsubscribe later if needed
};

export const exportTaxSummaryToCSV = (orders, startDate, endDate) => {
  try {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += "Order Number,Municipality,State Taxes6,State Taxes105,State Taxes Other,Sub Total 6,Sub Total 105,Sub Total Other\n";

    orders.forEach(order => {
      const orderDate = new Date(order.createdAt.iso).toLocaleDateString();
      csvContent += `${order.orderNumber},${order.taxData.foodTaxes.municipality.total},${order.taxData.foodTaxes.state.stateTaxes6},${order.taxData.foodTaxes.state.stateTaxes105},${order.taxData.foodTaxes.state.stateTaxesOther},${order.subtotalData.restaurantSubtotals6},${order.subtotalData.restaurantSubtotals105},${order.subtotalData.restaurantSubtotalsOther}\n`;
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `tax_summary_${startDate.split('T')[0]}_${endDate.split('T')[0]}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return true;
  } catch (error) {
    console.error('Error exporting tax summary to CSV:', error);
    throw error;
  }
};