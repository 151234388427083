import React, { useState } from 'react';
import { View, Text, StyleSheet, Pressable, Dimensions } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import colors from '../constants/colors';
import { setExpirationDateForDocument, deleteDocumentForUser } from '../services/api/restaurant';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const { width } = Dimensions.get('window');

const isMobile = width < 768;

const Document = ({ document }) => {
  const [focus, setFocus] = useState(false);

  const showFullScreen = (doc) => {
    window.open(doc, '_blank');
  };
  
  const handleChangeExpiresAt = async (date, document) => {
    await setExpirationDateForDocument(document.id, date.toISOString());

    window.location.reload();
  };
  
  return (
    <View style={{ borderWidth: 1, borderColor: colors.lightGrey, borderRadius: 8, padding: 5, width: 300, zIndex: 9 }} key={document.id}>
      <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
        <View>
          <Text style={styles.sectionTitle}>{document.get('documentCode')}</Text>
        </View>
      </View>
      <View style={styles.uploadArea}>
        <Icon name="description" size={34} color={colors.mediumGrey} />
        <Text style={{ marginTop: 5, color: colors.mediumGrey }}>Documento</Text>

        <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
          <Pressable onPress={() => handleDeleteDocument(document)}>
            <Icon name="delete" size={32} color={colors.primary} />
          </Pressable>
          <Pressable onPress={() => showFullScreen(document.get('image'))}>
            <Icon name="preview" size={32} color={colors.primary} />
          </Pressable>
        </View>
      </View>

      <View style={[styles.inputContainer, { zIndex: 9 }]}>
        <SingleDatePicker
          date={document.get('expiresAt') ? moment(document.get('expiresAt')) : null}
          onDateChange={(date) => handleChangeExpiresAt(date, document)}
          focused={focus}
          onFocusChange={({ focused }) => setFocus(focused)}
          numberOfMonths={1}
          displayFormat="DD-MMM-YYYY"
          showClearDate={true}
          isOutsideRange={() => false}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.darkGrey,
    // marginBottom: 10,
    flex: 1,
  },
  uploadArea: {
    borderWidth: 2,
    borderColor: colors.lightGrey,
    borderStyle: 'dashed',
    borderRadius: 8,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-all',
    marginTop: 10,
    marginBottom: 20,
  },
  inputContainer: {
    marginBottom: 20,
    flexDirection: isMobile ? 'column' : 'row',
    width: '100%',
  }
});

export default Document;